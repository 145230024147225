<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container border-custom">
        <div class="inactive-user">
          <div class="row">
            <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12 d-flex">
              <div class="goback">
                <a class="img-back" @click="modalCFgoback('top', true)">
                  <div class="icon-back">
                    <a-icon type="arrow-left" />
                  </div>
                </a>
              </div>
              <div class="">
                <div class="text-back">
                  {{
                    displayPageTitle(messageType, lbl['smart-target-header'])
                  }}
                  {{ targetBoxData.heading[currentLocale] }}
                  {{ 'Via ' + mapType(messageType) }}
                </div>
                <div class="text-back mt-3 d-flex">
                  <span
                    v-if="$route.query.mode !== 'create'"
                    :class="['text-total d-flex', campaignStatus]"
                    ><div :class="['statusIcon mr-2', campaignStatus]"></div>
                    {{ campaignStatus }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mx-auto content-inner-container">
        <div class="inactive-user">
          <div v-if="$route.query.mode !== 'create'" class="campaignContainer">
            <div class="campaignHeader">
              {{ lbl['smart-target-campaigndata'] }}
            </div>
            <div class="campaignList">
              <div class="campaignItem" @click="modaldetail(true, 'Target')">
                <div class="itemhead d-flex">
                  <img
                    class="mr-1"
                    src="@/assets/images/messageboxz.svg"
                    alt=""
                    width="24"
                    height="24"
                  />{{ lbl['smart-target-sendto'] }}
                </div>
                <div class="itemvalue d-flex justify-content-between">
                  <div>{{ campaignResult.TotalSent }}</div>
                  <div>{{ '>' }}</div>
                </div>
              </div>
              <div class="campaignItem" @click="modaldetail(true, 'Sent')">
                <div class="itemhead d-flex">
                  <img
                    class="mr-1"
                    src="@/assets/images/arrowup.svg"
                    alt=""
                    width="24"
                    height="24"
                  />
                  {{ lbl['smart-target-send'] }}
                </div>
                <div class="itemvalue green d-flex justify-content-between">
                  <div>{{ campaignResult.CountSent }}</div>
                  <div>{{ '>' }}</div>
                </div>
              </div>
              <div
                v-if="toLower(messageType) !== 'sms'"
                class="campaignItem"
                @click="modaldetail(true, 'Unique_Clicked')"
              >
                <div class="itemhead d-flex">
                  <img
                    class="mr-1"
                    src="@/assets/images/curserclick.svg"
                    alt=""
                    width="24"
                    height="24"
                  />
                  {{ lbl['smart-target-click'] }}
                </div>
                <div
                  v-if="toLower(messageType) !== 'sms'"
                  class="itemvalue orange d-flex justify-content-between"
                >
                  <div>{{ campaignResult.CountClick }}</div>
                  <div>{{ '>' }}</div>
                </div>
                <div
                  v-else
                  class="itemvalue orange d-flex justify-content-between"
                >
                  <div>{{ '-' }}</div>
                </div>
              </div>
              <div
                v-if="toLower(messageType) !== 'sms'"
                class="campaignItem"
                @click="modaldetail(true, 'Redeemed')"
              >
                <div class="itemhead d-flex">
                  <img
                    class="mr-1"
                    src="@/assets/images/Double-check.svg"
                    alt=""
                    width="24"
                    height="24"
                  />
                  {{ lbl['smart-target-redeemed'] }}
                </div>
                <div class="itemvalue yellow d-flex justify-content-between">
                  <div>
                    {{
                      campaignResult.CountRedeemed
                        ? campaignResult.CountRedeemed
                        : $route.query.redeemed
                    }}
                  </div>
                  <div>{{ '>' }}</div>
                </div>
              </div>
              <div
                v-if="toLower(messageType) !== 'sms'"
                class="campaignItem"
                @click="modaldetail(true, 'Used')"
              >
                <div class="itemhead d-flex">
                  <img
                    class="mr-1"
                    src="@/assets/images/Chat-smile.svg"
                    alt=""
                    width="24"
                    height="24"
                  />
                  {{ lbl['smart-target-used'] }}
                </div>
                <div class="itemvalue gray d-flex justify-content-between">
                  <div>
                    {{
                      campaignResult.CountUsed
                        ? campaignResult.CountUsed
                        : $route.query.used
                    }}
                  </div>
                  <div>{{ '>' }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              v-if="toLower(messageType) == 'line'"
              class="col-lg-3 box-left"
            >
              <div class="box-img">
                <img
                  v-if="!imgPreview"
                  class="w-100"
                  src="@/assets/images/blank-photo.png"
                  alt=""
                />
                <img
                  v-else-if="$route.query.mode == 'edit'"
                  :src="imgPreview + '/460'"
                  class="w-100 h-100"
                  alt=""
                />
                <img v-else :src="imgPreview" class="w-100 h-100" alt="" />
                <div
                  v-if="imgPreview && $route.query.mode !== 'view'"
                  class="cancelImg"
                  @click="cancelImg"
                >
                  x
                </div>
                <div v-if="$route.query.mode !== 'view'" class="select_artwork">
                  <div class="btn-artwork" @click="modalArtWord()">
                    <img
                      src="@/assets/images/smartnotify/wallpaper.svg"
                      alt=""
                    />
                    {{ lbl['select_free_artwork'] }}
                  </div>
                </div>
              </div>
              <div
                :class="[
                  'btn-bg-white',
                  $route.query.mode === 'view' ? 'disabled' : '',
                ]"
                @click="uploadArtWork($event)"
              >
                <input
                  @change="onImgFileChange($event)"
                  ref="uploadImg"
                  type="file"
                  accept="image/jpeg, image/png, image/jpg"
                  hidden
                />
                <img
                  v-if="$route.query.mode !== 'view'"
                  src="@/assets/images/upload.svg"
                  alt=""
                  width="24"
                  height="24"
                />
                <img
                  v-else
                  src="@/assets/images/uploaddisabled.svg"
                  alt=""
                  width="24"
                  height="24"
                />
                <span class="ml-2">
                  {{ lbl['upload_artwork'] }}
                </span>
              </div>
              <div
                @click="useRewardImg"
                :class="[
                  'btn-bg-orange',
                  $route.query.mode === 'view' ? 'disabled' : '',
                ]"
              >
                {{ lbl['use_reward_image'] }}
              </div>
              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>
                    {{ lbl['tooltip_1'] }}<br />
                    {{ lbl['tooltip_2'] }}<br />
                    {{ lbl['tooltip_3'] }}
                  </span>
                </template>
                <a-button class="btn-guildline">
                  <img
                    src="@/assets/images/infocircle.svg"
                    alt=""
                    width="24"
                    height="24"
                  />
                  <span class="ml-2">{{ lbl['guidline'] }}</span>
                </a-button>
              </a-tooltip>
            </div>
            <div class="col-lg-9 box-right">
              <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 mb-3">
                  <span class="text-checkbox">
                    {{ lbl['smart-target-target'] }}
                  </span>
                </div>
                <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12">
                  <div class="row">
                    <div
                      v-if="
                        ($route.query.mode === 'create' ||
                          $route.query.mode === 'edit') &&
                        targetBoxData.name
                      "
                      class="col-xl-8 col-lg-8 col-md-10 col-sm-10 col-10"
                    >
                      <div class="targetBoxContainer">
                        <div class="targetBox notaction">
                          <div
                            :class="['targetIcon']"
                            :style="targetBoxData.style"
                          >
                            <img
                              width="26"
                              height="26"
                              :src="
                                require(`@/assets/images/${targetBoxData.icon}.png`)
                              "
                              alt=""
                            />
                          </div>
                          <div class="targetDetail">
                            <div class="header">
                              {{ targetBoxData.heading[currentLocale] }}
                            </div>
                            <div class="subHeader">
                              {{ targetBoxData.description[currentLocale] }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-2 col-sm-2 col-2">
                      <div
                        class="
                          main-account
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                      >
                        <a-spin v-if="isLoadTotal" />
                        <div v-else class="count-user">
                          {{ totalUser }}
                          <!-- {{ $route.query.target }} -->
                          <span class="sub-account">
                            {{ lbl['smart-target-account'] }}
                          </span>
                        </div>
                        <div
                          v-if="totalUser"
                          class="view-user-detail"
                          @click="handleClickUserDetail()"
                        >
                          <span>
                            {{ lbl['manage-usr-view'] }}
                          </span>
                          <img
                            :src="require('@/assets/images/eye.png')"
                            alt=""
                            class="icon-eye"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 mb-3">
                  <span class="text-checkbox">{{ lbl['action'] }}</span>
                </div>
                <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                      <a-select
                        v-if="toLower(messageType) == 'sms'"
                        v-model="valueAction"
                        default-value="Rewards"
                        class="w-100"
                        @change="onChangeAction"
                        :disabled="$route.query.mode === 'view'"
                      >
                        <a-select-option value="No Action">
                          {{ lbl['no_action'] }}
                        </a-select-option>
                        <a-select-option value="Exiting Link">
                          {{ lbl['exiting_link'] }}
                        </a-select-option>
                      </a-select>
                      <a-select
                        v-else
                        v-model="valueAction"
                        default-value="Rewards"
                        class="w-100"
                        @change="onChangeAction"
                        :disabled="$route.query.mode === 'view'"
                      >
                        <a-select-option value="No Action">
                          {{ lbl['no_action'] }}
                        </a-select-option>
                        <a-select-option value="Rewards">
                          {{ lbl['rewards'] }}
                        </a-select-option>
                        <a-select-option value="Exiting Link">
                          {{ lbl['exiting_link'] }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      valueAction == 'Rewards' &&
                      !selectedReward.ID &&
                      $route.query.mode !== 'view'
                    "
                  >
                    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                      <div
                        class="btn-bg-white-reward w-100"
                        @click="modalReward()"
                      >
                        {{ lbl['select_reward'] }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      v-if="valueAction == 'Rewards' && selectedReward.ID"
                      class="
                        modal-preview
                        col-xl-6 col-lg-8 col-md-8 col-sm-12 col-12
                      "
                    >
                      <div class="box-item mx-0">
                        <img
                          :src="selectedReward.ImageUrl"
                          alt=""
                          style="width: 50px; height: 50px; object-fit: cover"
                        />
                        <div class="box-detail-item">
                          <span class="detail-item">
                            {{
                              currentLocale == 'th'
                                ? selectedReward.Name
                                : selectedReward.NameEN
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="box-item-total mx-0">
                        <div class="box-detail-total">
                          <span class="detail-item">
                            {{ lbl['total-quota'] }}
                            {{ selectedReward.Quantity }}</span
                          >
                          <br />
                          <span class="detail-item"
                            >{{ lbl['period'] }}
                            {{ formatDateString(selectedReward.StartDate) }} -
                            {{ formatDateString(selectedReward.EndDate) }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        valueAction == 'Rewards' &&
                        selectedReward.ID &&
                        $route.query.mode !== 'view'
                      "
                      class="col-xl-4 col-lg-4 col-md-8 col-sm-12 col-12"
                    >
                      <button
                        class="w-100 changeRewardBtn"
                        @click="modalReward()"
                      >
                        <img
                          class="mr-2"
                          src="@/assets/images/repeatorg.svg"
                          alt=""
                          width="24"
                          height="24"
                        />{{ lbl['smarttarget-changeReward'] }}
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div v-if="valueAction == 'Exiting Link'">
                        <a-input
                          v-model="link"
                          placeholder="https://"
                          :class="[
                            'mt-3',
                            linkState && linkState.length
                              ? 'inplinkError'
                              : 'linkError',
                          ]"
                          :maxLength="handleMaxLengthLink"
                          :disabled="$route.query.mode === 'view'"
                        />
                        <div
                          :class="[
                            'mt-2 ml-2 inpLink',
                            linkState && linkState.length
                              ? 'linkError'
                              : 'linkError',
                          ]"
                        >
                          <div
                            v-if="
                              linkState &&
                              linkState.length &&
                              handleErr(linkState).prefix
                            "
                          >
                            {{ lbl['fgf-requirelink'] }}
                          </div>
                          <div
                            v-if="
                              linkState &&
                              linkState.length &&
                              handleErr(linkState).internal
                            "
                          >
                            {{ lbl['smarttarget-link-err'] }}
                          </div>
                        </div>
                        <div class="text-danger mt-2">
                          {{
                            toLower(messageType) == 'sms'
                              ? lbl['smart-target-externallink-sms']
                              : lbl['smart-target-externallink']
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="toLower(messageType) == 'email'" class="row mt-5">
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 mb-3">
                  <span class="text-checkbox">{{ lbl['subject'] }}</span>
                </div>
                <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12">
                  <a-input
                    v-model="subject"
                    :placeholder="lbl['subject_placeholder']"
                    :maxLength="100"
                    :disabled="$route.query.mode === 'view'"
                  />
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 mb-3">
                  <span class="text-checkbox">{{ lbl['text'] }}</span>
                </div>
                <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12">
                  <div v-if="toLower(messageType) == 'email'">
                    <vue-editor
                      v-model="content"
                      :editor-toolbar="editorOption"
                      :disabled="$route.query.mode === 'view'"
                    />
                    <div class="warning-input mt-2">
                      {{ lbl['smart-target-warnign-input-content-rule'] }}
                    </div>
                  </div>
                  <a-textarea
                    v-else
                    v-model="valueArea"
                    :placeholder="lbl['your_message']"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                    :maxLength="handleMaxLengthMessage"
                    :disabled="$route.query.mode === 'view'"
                  />
                  <div
                    v-if="toLower(messageType) !== 'email'"
                    class="text-right"
                  >
                    {{
                      toLower(messageType) == 'sms'
                        ? valueArea.length + link.length + ' / '
                        : valueArea.length + ' / '
                    }}
                    {{ toLower(messageType) == 'sms' ? '70' : '400' }}
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 mb-3">
                  <span class="text-checkbox">{{ lbl['time'] }}</span>
                </div>
                <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12">
                  <div class="row">
                    <div class="col-12">
                      <a-radio-group
                        v-model="value"
                        @change="onChangeDateType"
                        :disabled="$route.query.mode === 'view'"
                      >
                        <a-radio :style="radioStyle" :value="1">
                          <span class="color-gray">{{ lbl['send_now'] }}</span>
                        </a-radio>
                        <a-radio :style="radioStyle" :value="2" class="mt-2">
                          <span class="color-gray">
                            {{ lbl['schedule_send'] }}
                          </span>
                          <span class="text-danger">
                            {{ lbl['smart-target-warringdate'] }}
                          </span>
                        </a-radio>
                      </a-radio-group>
                    </div>
                    <div
                      class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 mt-3"
                    >
                      <a-date-picker
                        :disabled="value != 2 || $route.query.mode === 'view'"
                        :placeholder="'Select Date'"
                        :format="'DD/MM/YYYY'"
                        :disabled-date="disabledStartDate"
                        v-model="dateTime.startDate"
                        class="w-100"
                      />
                    </div>
                    <div
                      class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mt-3"
                    >
                      <a-time-picker
                        v-model="dateTime.startTime"
                        class="w-100"
                        :format="'HH:mm'"
                        :disabled="value != 2 || $route.query.mode === 'view'"
                      />
                    </div>
                    <!-- <div
                      class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mt-3"
                    >
                      <a-date-picker
                        :disabled="value != 2"
                        :placeholder="'Select End Date'"
                        v-model="dateTime.endDate"
                        class="w-100"
                      />
                    </div>
                    <div
                      class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3"
                    >
                      <a-time-picker
                        class="w-100"
                        v-model="dateTime.endTime"
                        :disabled="value != 2"
                      />
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <!-- footer -->
            <div
              v-if="$route.query.mode === 'create'"
              class="col-md-5 col-12 row align-items-end"
              :style="{ padding: '0 0 0 45px' }"
            >
              <button
                :disabled="!formState"
                @click="clickSaveDraft()"
                class="btn-test-line logout col-md-5 col-12"
              >
                {{ 'Save as Draft' }}
              </button>
              <button
                :disabled="!formState"
                class="btn-test-line col-md-7 col-12 mt-2"
                @click="upLoadImgToTestLine"
              >
                <span v-if="toLower(messageType) == 'email'">
                  {{ lbl['send_to_email'] }}
                </span>
                <span v-else-if="toLower(messageType) == 'sms'">
                  {{ lbl['send_to_sms'] }}
                </span>
                <span v-else>
                  {{
                    lineIdStore
                      ? lbl['send_to_line']
                      : lbl['smart-loginbeforesend']
                  }}
                </span>
              </button>
            </div>

            <div
              v-if="$route.query.mode === 'create'"
              class="
                col-md-7 col-12
                box-publish
                d-flex
                justify-content-end
                align-items-end
              "
            >
              <div
                class="btn-bg-orange-publish cancel"
                @click="modalCFgoback('cancel', true)"
              >
                {{ lbl['confirm-box-cancel-button'] }}
              </div>
              <button
                :disabled="!formState"
                class="btn-bg-orange-publish"
                @click="modalPreviewAndPublish()"
              >
                {{ lbl['preview_and_Publish'] }}
              </button>
            </div>
          </div>
          <div
            v-if="$route.query.mode === 'edit'"
            class="d-flex justify-content-end"
          >
            <div
              class="btn-bg-orange-publish"
              @click="upLoadImg('publish', adsId)"
            >
              {{ lbl['user-permissions-save'] }}
            </div>
          </div>
        </div>
      </div>

      <!-- Modal reward -->
      <a-modal
        v-model="selectReward"
        :title="lbl['select_reward']"
        :footer="null"
        :width="800"
        centered
        class="modal-inactive"
        :class="[currentLocale]"
      >
        <SelectReward
          :dataDetail="rewardDataList"
          v-if="selectReward"
          @closeModal="closeModal"
          :selectedRewardhandler="selectedRewardhandler"
        />
      </a-modal>

      <!-- Modal invite -->
      <a-modal
        v-model="showInviteBox"
        :title="
          toLower(messageType) == 'email'
            ? lbl['send_to_email']
            : lbl['send_to_sms']
        "
        :footer="null"
        :closable="false"
        centered
        class="modal-invite"
        :class="[currentLocale]"
      >
        <div class="invite-container">
          <a-input
            v-model="inviteUser"
            :placeholder="
              toLower(messageType) == 'email'
                ? lbl['smart-target-email-invite-placeholder']
                : lbl['smart-target-sms-invite-placeholder']
            "
            :maxLength="toLower(messageType) == 'sms' ? 10 : 200"
            :disabled="!formState"
          />
          <a-button
            :disabled="isDisableSentTest"
            class="invite-btn"
            @click="sentTest()"
          >
            Invite
          </a-button>
        </div>
      </a-modal>

      <!-- artwork -->
      <a-modal
        v-model="selectArtWork"
        :title="lbl['select_free_artwork']"
        :footer="null"
        :width="800"
        centered
        class="modal-inactive modal-artwork"
        :class="[currentLocale]"
      >
        <SelectArtWork
          v-if="selectArtWork"
          @closeModal="closeModal"
          :selectArtWorkImg="selectArtWorkImg"
        />
      </a-modal>

      <!-- preview -->
      <a-modal
        v-model="previewAndPublish"
        :title="lbl['preview_campaign']"
        :footer="null"
        :width="800"
        centered
        class="modal-inactive modal-preview"
        :class="[currentLocale]"
      >
        <PreviewAndPublishEmail
          v-if="previewAndPublish && toLower(messageType) == 'email'"
          :selectedReward="selectedReward"
          :valueAction="valueAction"
          :link="link"
          :subject="subject"
          :text="content"
          :dateTime="dateTime"
          :createCampaign="upLoadImg"
          :targetBoxData="targetBoxData"
          :usercount="resUserList.UserCount"
          @closeModal="closeModal"
          :imgPreview="imgPreview"
          :modalConfirmCreate="modalConfirmCreate"
        />
        <PreviewAndPublishSMS
          v-if="previewAndPublish && toLower(messageType) == 'sms'"
          :selectedReward="selectedReward"
          :valueAction="valueAction"
          :link="link"
          :text="valueArea"
          :dateTime="dateTime"
          :createCampaign="upLoadImg"
          :targetBoxData="targetBoxData"
          :usercount="resUserList.UserCount"
          @closeModal="closeModal"
          :imgPreview="imgPreview"
          :modalConfirmCreate="modalConfirmCreate"
          :balance="limitSmsCurrent"
          :isUnlimitSMS="isUnlimitSMS"
        />
        <PreviewAndPublish
          v-if="
            previewAndPublish &&
            toLower(messageType) !== 'email' &&
            toLower(messageType) !== 'sms'
          "
          :selectedReward="selectedReward"
          :valueAction="valueAction"
          :link="link"
          :text="valueArea"
          :dateTime="dateTime"
          :createCampaign="upLoadImg"
          :targetBoxData="targetBoxData"
          :usercount="resUserList.UserCount"
          @closeModal="closeModal"
          :imgPreview="imgPreview"
          :modalConfirmCreate="modalConfirmCreate"
          :balance="limitLineCurrent"
        />
      </a-modal>

      <!-- detail -->
      <a-modal
        v-model="modalviewDetail"
        :title="detailTitle"
        :footer="null"
        :width="800"
        centered
        class="modal-inactive modal-preview"
        :class="[currentLocale]"
      >
        <ViewDetail
          @closeModal="modalviewDetail = false"
          :columnsDetail="columnsDetail"
          :data="detailDataFilter"
          :id="$route.query.id + ''"
        />
      </a-modal>
      <!-- user detail -->
      <a-modal
        v-model="modalviewUserDetail"
        :title="lbl['Target_User_Details']"
        :footer="null"
        :width="800"
        centered
        class="modal-inactive modal-preview"
        :class="[currentLocale]"
      >
        <ViewDetail
          @closeModal="modalviewUserDetail = false"
          :columnsDetail="columnsUserDetail"
          :data="detailDataTotal"
          :id="$route.query.id + ''"
        />
      </a-modal>

      <!-- confirm Go Back -->
      <b-modal
        v-model="modalGoBack"
        modal-class="modal-Confirm"
        :modalClass="[currentLocale]"
        centered
        hide-header
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
      >
        <div class="modal-base">
          <div class="row mt-3">
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2">
              <img
                :src="require('@/assets/images/smartnotify/Sign-out.svg')"
                alt=""
              />
            </div>
            <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10">
              <div class="modal-base-head">
                {{ lbl['smart-target-leave-without-saving'] }}
              </div>
              <div class="modal-base-body mt-2">
                {{ lbl['smart-target-leave-without-detail'] }}
              </div>
            </div>
          </div>
          <div class="modal-base-footer mt-4">
            <button
              class="btn btn-outline-secondary"
              @click="btnCancelGoBack()"
            >
              {{ lbl['smart-target-back-to-creating-page'] }}
            </button>
            <button class="btn btn-warning ml-2" @click="cancelGoto()">
              {{ lbl['smart-target-leave-now'] }}
            </button>
          </div>
        </div>
      </b-modal>

      <!-- use reward img -->
      <b-modal
        v-model="modalUseReward"
        modal-class="modal-Confirm"
        :modalClass="[currentLocale]"
        centered
        hide-header
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
      >
        <div class="modal-base">
          <div class="row mt-3">
            <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10">
              <div class="modal-base-head">
                {{ lbl['smart-target-selectrewardheader'] }}
              </div>
              <div class="modal-base-body mt-2">
                {{ lbl['smart-target-selectrewardsubheader'] }}
              </div>
            </div>
          </div>
          <div class="modal-base-footer mt-4">
            <button
              class="btn btn-warning ml-2"
              @click="modalUseReward = false"
            >
              {{ lbl['user-permissions-ok'] }}
            </button>
          </div>
        </div>
      </b-modal>

      <!-- create failed -->
      <b-modal
        v-model="modalFailed"
        modal-class="modal-Confirm"
        :modalClass="[currentLocale]"
        centered
        hide-header
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
      >
        <div class="modal-base">
          <div class="row mt-3">
            <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10">
              <div class="modal-base-head">
                {{
                  toLower(messageType) == 'email'
                    ? lbl['smart-target-fail-createheader'] +
                      lbl['smart-target-email']
                    : toLower(messageType) == 'sms'
                    ? lbl['smart-target-fail-createheader'] +
                      lbl['smart-target-sms']
                    : lbl['smart-target-fail-createheader'] +
                      lbl['smart-target-line']
                }}
              </div>
              <div class="modal-base-body mt-2">
                {{ lbl['smart-target-failcreatesubheader'] }}
              </div>
            </div>
          </div>
          <div class="modal-base-footer mt-4">
            <button class="btn btn-warning ml-2" @click="modalFailed = false">
              {{ lbl['user-permissions-ok'] }}
            </button>
          </div>
        </div>
      </b-modal>

      <!-- upload image fail -->
      <b-modal
        v-model="modalFailedUpload"
        modal-class="modal-Confirm"
        :modalClass="[currentLocale]"
        centered
        hide-header
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
      >
        <div class="modal-base">
          <div class="row mt-3">
            <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10">
              <div class="modal-base-head">
                {{ lbl['smart-target-failuploadheader'] }}
              </div>
              <div class="modal-base-body mt-2">
                {{ lbl['smart-target-failuploadsubheader'] }}
              </div>
            </div>
          </div>
          <div class="modal-base-footer mt-4">
            <button
              class="btn btn-warning ml-2"
              @click="modalFailedUpload = false"
            >
              {{ lbl['user-permissions-ok'] }}
            </button>
          </div>
        </div>
      </b-modal>
    </div>

    <!-- confirm create now -->
    <b-modal
      v-model="modalCreateNow"
      modal-class="modal-Confirm"
      :modalClass="[currentLocale]"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="modal-base">
        <div class="row mt-3">
          <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10">
            <div class="modal-base-head">
              {{ lbl['smart-target-confirmcreatenow'] }}
            </div>
            <div class="modal-base-body mt-2">
              {{ lbl['smart-target-confirmcreatenowtext'] }}
            </div>
          </div>
        </div>
        <div class="footerAction">
          <button class="closeBtn" @click="modalConfirmCreate(false)">
            {{ lbl['account-setup-cancel-button'] }}
          </button>
          <button class="saveBtn" @click="upLoadImg('publish')">
            {{ lbl['send_now'] }}
          </button>
        </div>
      </div>
    </b-modal>

    <div
      v-if="$route.query.mode === 'edit'"
      class="d-flex my-3 w-100 deleteBtnContainer"
    >
      <div class="deleteBtn" @click="toggleDeleteModal(true)">
        <a-icon
          :style="{ color: '#ff5252', fontSize: '18px ' }"
          theme="filled"
          type="delete"
          class="mr-1"
        />{{ lbl['smarttarget-cancelCampaign'] }}
      </div>
    </div>
    <DeleteModal
      :toggle="toggleDeleteModal"
      :isDeleteModal="isDeleteModal"
      :cfDelete="deleteCampiagn"
    />

    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import Locale from '@/helper/locale.js'
import SelectReward from '@/module/SmartNotify/modal/SelectReward.vue'
import SelectArtWork from '@/module/SmartNotify/modal/SelectArtWork.vue'
import PreviewAndPublish from '@/module/SmartNotify/modal/PreviewAndPublish.vue'
import PreviewAndPublishEmail from '@/module/SmartNotify/modal/PreviewAndPublishEmail.vue'
import PreviewAndPublishSMS from '@/module/SmartNotify/modal/PreviewAndPublishSMS.vue'
import ViewDetail from '@/module/SmartNotify/modal/ViewDetail.vue'
import Header from '@/components/Layout/Header'
import BzbsPlanReward from '@/core/PlanReward/service/BzbsPlanReward'
import BzbsSmarttarget from '@/core/Smarttarget/service/BzbsSmarttarget'
import liff from '@line/liff'
import Vue from 'vue'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import XLSX from 'xlsx'
import moment from 'moment'
import DeleteModal from './modal/DeleteModal'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import { VueEditor } from 'vue2-editor'
// import cache from '@/helper/Cache'
// import moment from 'moment'
export default {
  name: 'InactiveUser',
  components: {
    SelectReward,
    SelectArtWork,
    ViewDetail,
    PreviewAndPublish,
    PreviewAndPublishEmail,
    PreviewAndPublishSMS,
    Header,
    DeleteModal,
    AlertModal,
    VueEditor,
  },
  mixins: [Mixin],
  data: function () {
    return {
      host: window.location.origin,
      columnsUserDetail: [
        {
          title: 'User ID',
          dataIndex: 'UserID',
          key: 'UserID',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: 150,
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Mobile',
          dataIndex: 'ContactNumber',
          key: 'ContactNumber',
        },
        {
          title: 'Email',
          dataIndex: 'Email',
          key: 'Email',
        },
      ],
      columnsDetail: [
        {
          title: 'User ID',
          dataIndex: 'UserID',
          key: 'UserID',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: 150,
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Mobile',
          dataIndex: 'ContactNumber',
          key: 'ContactNumber',
        },
        {
          title: 'Email',
          dataIndex: 'Email',
          key: 'Email',
        },
        {
          title: 'Reedeem Date',
          dataIndex: 'Timestamp',
          key: 'Timestamp',
          width: 150,
        },
      ],
      isPhase2: true,
      valueAction: 'No Action',
      valueArea: '',
      content: '',
      editorOption: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['image'],
        ['clean'], // remove formatting button
      ],
      valueTime: false,
      value: 1,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      maxLength: 1000,
      selectReward: false,
      rewardDataList: [],
      selectArtWork: false,
      previewAndPublish: false,
      modalGoBack: false,
      imgPreview: '',
      modalUseReward: false,
      modalFailed: false,
      modalFailedUpload: false,
      modalviewDetail: false,
      modalviewUserDetail: false,
      detailTitle: '',
      modalCreateNow: false,
      selectedReward: {},
      dateTime: {
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
      },
      link: '',
      subject: '',
      window: window,
      logginLine: false,
      resUserList: [],
      resCampaign: [],
      campaignStatus: '',
      isDeleteModal: false,
      rewardId: '',
      file: null,
      campaignResult: {},
      adsId: '',
      userListId: '',
      detailDataTotal: [],
      detailDataSent: [],
      detailDataClick: [],
      detailDataRedeem: [],
      detailDataUsed: [],
      detailDataFilter: [],
      userDetailDataFilter: [],
      totalUser: 0,
      currentLocale: 'th',
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      liffId: '',
      LiffLanding: '',
      isUnlimitSMS: false,
      limitSmsCurrent: null,
      limitLineCurrent: null,
      messageType: null,
      showInviteBox: false,
      inviteUser: '',
      isLoadTotal: false,
    }
  },
  computed: {
    handleMaxLengthLink() {
      if (this.toLower(this.messageType) == 'sms') {
        if (this.valueArea) {
          return 70 - this.valueArea.length
        } else {
          return 70
        }
      } else {
        return 200
      }
    },
    handleMaxLengthMessage() {
      if (this.toLower(this.messageType) == 'sms') {
        if (this.link) {
          return 70 - this.link.length
        } else {
          return 70
        }
      } else {
        return 400
      }
    },
    targetBoxData() {
      let fromListEx = {
        color: 'orange',
        heading: {
          en: 'Inactive',
          th: 'ลูกค้าที่ไม่ได้ใช้งาน',
          ph: 'Inactive',
        },
        description: {
          en: 'No longer users who have not logged in in the last 30 days',
          th: 'ลูกค้าที่ไม่ได้ล็อคอินมากกว่า 30 วัน',
          ph: 'No longer users who have not logged in in the last 30 days',
        },
        filename: 'asd',
        icon: 'inactive',
        name: 'inactive_30',
      }
      return JSON.parse(sessionStorage.getItem('target')) || fromListEx
    },
    targetDataDetail() {
      return JSON.parse(sessionStorage.getItem('smartTargetData'))
    },
    lineIdStore() {
      return JSON.parse(sessionStorage.getItem('lineIdStore'))
    },
    dateTimeState() {
      if (this.value === 1) return true
      return this.dateTime.startDate && this.dateTime.startTime
    },
    linkState() {
      var error = []
      if (this.link == '') return null
      if (!(this.link.includes('http://') || this.link.includes('https://'))) {
        error.push('prefix')
      }
      if (this.link.includes('/landing') || this.link.includes('/langing2')) {
        error.push('internal')
      }
      return error
    },
    actionState() {
      if (this.valueAction === 'Exiting Link') {
        return this.linkState
      }
      if (this.valueAction === 'Rewards') {
        return this.selectedReward.ImageUrl
      } else {
        return true
      }
    },
    formState() {
      var correct_link = false
      if (this.valueAction == 'Exiting Link') {
        if (typeof this.actionState == 'boolean') {
          correct_link = this.actionState
        } else {
          if (this.actionState) {
            if (this.actionState.length) correct_link = false
            else correct_link = true
          } else {
            correct_link = false
          }
        }
      } else if (this.valueAction == 'Rewards') {
        if (this.link) correct_link = true
        else correct_link = false
      } else {
        correct_link = true
      }
      if (this.toLower(this.messageType) == 'email') {
        return (
          this.dateTimeState && this.subject && this.content && correct_link
        )
      } else {
        return this.dateTimeState && this.valueArea && correct_link
      }
    },
    isDisableSentTest() {
      if (this.toLower(this.messageType) == 'sms') {
        if (this.inviteUser) {
          if (this.inviteUser.length == 10) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return !this.inviteUser
      }
    },
  },
  created() {
    Account.bzbsAnalyticTracking(
      'smart_target_create',
      'smart_target',
      'view_smart_target_create',
      'on view',
    )
    this.messageType = this.$route.query.type
    this.init()
    if (this.$route.query.openreward === 'open') {
      this.valueAction = 'Rewards'
      this.modalReward()
    }
    if (this.$route.query.mode !== 'create' && this.$route.query.target) {
      this.totalUser = this.$route.query.target
    }
    if (this.targetBoxData && this.$route.query.mode === 'create') {
      this.createUserList(
        this.targetBoxData.name,
        this.targetBoxData.description,
        this.targetBoxData.filename,
      )
    }

    if (this.targetDataDetail) {
      this.valueAction = this.targetDataDetail.valueAction
      this.selectedReward = this.targetDataDetail.selectedReward
      if (this.targetDataDetail.link) this.link = this.targetDataDetail.link
      this.valueArea = this.targetDataDetail.valueArea
      this.value = this.targetDataDetail.value
      this.dateTime = this.targetDataDetail.dateTime
      this.imgPreview = this.targetDataDetail.imgPreview
    }
    if (this.$route.query.userId && this.$route.query.userId !== 'undefined') {
      sessionStorage.setItem(
        'lineIdStore',
        JSON.stringify(this.$route.query.userId),
      )
    }
  },
  mounted() {
    this.getRewardList()
    this.currentLocale = Locale.getLocaleShort()
  },
  methods: {
    init() {
      this.handleFooter(true)
      this.getCRMPlusProfile()
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },
    formatDateString(dateString, format = 'DD/MM/YYYY', calHours = null) {
      return moment(dateString * 1000)
        .add(calHours, 'hours')
        .format(format)
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue
      var date = new Date()
      if (!startValue || !endValue) {
        return startValue.valueOf() < date.setDate(date.getDate() - 1).valueOf()
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    onChangeDateType() {
      this.dateTime = {
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
      }
    },
    modaldetail(mode, filter) {
      this.modalviewDetail = mode
      this.detailTitle = this.lbl[filter + '_Details']
      if (
        filter === 'Unique_Clicked' &&
        this.toLower(this.messageType) !== 'sms'
      ) {
        this.detailDataFilter = this.detailDataClick
        this.setTitle('Timestamp')
      } else if (
        filter === 'Unique_Clicked' &&
        this.toLower(this.messageType) == 'sms'
      ) {
        this.modalviewDetail = false
      } else if (filter === 'Sent') {
        this.detailDataFilter = this.detailDataSent
        this.setTitle('Timestamp')
      } else if (filter === 'Target') {
        this.detailDataFilter = this.detailDataTotal
        this.setTitle('Timestamp')
      } else if (filter === 'Redeemed') {
        this.detailDataFilter = this.detailDataRedeem
        this.setTitle('Timestamp')
      } else if (filter === 'Used') {
        this.detailDataFilter = this.detailDataUsed
        this.setTitle('Used Date')
      }
    },
    modaluserdetail() {
      this.modalviewUserDetail = true
    },
    setTitle(titletext) {
      this.columnsDetail = this.columnsDetail.map(data => {
        if (data.key == 'Timestamp') {
          data.title = titletext
        }
        return data
      })
    },
    modalReward() {
      // this.getRewardList()
      Account.bzbsAnalyticTracking(
        'smart_target_create',
        'smart_target',
        'click_smart_target_select_reward',
        'on click',
      )
      setTimeout(() => (this.selectReward = true), 300)
      Account.bzbsAnalyticTracking(
        'smart_target_create',
        'smart_target',
        'view_smart_target_select_reward',
        'on view',
      )
    },
    modalArtWord() {
      if (this.$route.query.mode !== 'view') {
        this.selectArtWork = true
      }
    },
    modalFailCreate(data) {
      this.modalFailed = data
    },
    modalConfirmCreate(data) {
      this.modalCreateNow = data
      if (!data) this.previewAndPublish = true
    },
    modalCFgoback(position, data) {
      if (position == 'cancel') {
        Account.bzbsAnalyticTracking(
          'smart_target_create',
          'smart_target',
          'click_smart_target_create_cancel',
          'on click',
        )
      } else {
        Account.bzbsAnalyticTracking(
          'smart_target_create',
          'smart_target',
          'click_smart_target_create_close',
          'on click',
        )
      }
      if (this.$route.query.mode === 'create') {
        this.modalGoBack = data
      } else {
        this.$router.push({ name: 'SmartNotify' })
      }
    },
    modalPreviewAndPublish() {
      Account.bzbsAnalyticTracking(
        'smart_target_create',
        'smart_target',
        'click_smart_target_create_save',
        'on click',
      )
      Account.bzbsAnalyticTracking(
        'smart_target_create',
        'smart_target',
        'view_smart_target_create_preview',
        'on view',
      )
      if (this.checkLinkValid()) {
        if (this.toLower(this.messageType) == 'email') {
          this.validateContentSize(this.customHTML(this.content)).then(
            result => {
              if (result) {
                this.previewAndPublish = true
              } else {
                this.handleLoading(false)

                this.alertModalAction(
                  this.lbl['alert-box-error-header'],
                  this.lbl['smart-target-warnign-over-size'],
                  'error',
                  true,
                )
              }
            },
          )
        } else {
          this.previewAndPublish = true
        }
      } else {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          this.lbl['smart-target-wrong-link'],
          'error',
          true,
        )
      }
    },
    useRewardImg() {
      Account.bzbsAnalyticTracking(
        'smart_target_create',
        'smart_target',
        'click_smart_target_campaign_cover',
        'on click',
      )
      if (this.$route.query.mode !== 'view') {
        if (this.valueAction === 'Rewards' && this.selectedReward.ImageUrl) {
          BzbsSmarttarget.upLoadImgURL(this.selectedReward.ImageUrl).then(
            res => {
              this.imgPreview = res.data.data.info.baseUrl + '/1040'
              this.file = null
            },
          )
        } else {
          this.modalUseReward = true
        }
      }
    },
    selectedRewardhandler(data) {
      this.imgPreview = ''
      this.selectedReward = data
      if (this.valueAction === 'Rewards') {
        this.createRewardLink()
      }
    },
    createRewardLink() {
      let qsObject = {
        target_page: `detail/${this.selectedReward.ID}?ads_id=`,
      }
      const qs = new URLSearchParams(qsObject)
      // this.link = `${Vue.bzbsConfig.bzbsConsumerUrl}/landing2/${
      //   cache.get('CRMUser').AppId
      // }?${qs}@@(ads_id)@@`
      this.link = `https://liff.line.me/${this.liffId}?${qs}@@(ads_id)@@`
    },
    closeModal() {
      this.selectReward = false
      this.selectArtWork = false
      this.previewAndPublish = false
    },
    selectArtWorkImg(path) {
      console.log('path', path)
      this.imgPreview = path
      this.file = null
    },
    uploadArtWork() {
      Account.bzbsAnalyticTracking(
        'smart_target_create',
        'smart_target',
        'click_smart_target_upload_img',
        'on click',
      )
      if (this.$route.query.mode !== 'view') {
        this.$refs.uploadImg.value = null
        this.$refs.uploadImg.click()
      }
    },
    onImgFileChange(e) {
      const _URL = window.URL || window.webkitURL
      let img, width, height
      let objectUrl = _URL.createObjectURL(e.target.files[0])
      img = new Image()
      let vmthis = this
      img.onload = function () {
        width = this.width
        height = this.height
        if (width == 1040 && height == 1040) {
          vmthis.file = e.target.files[0]
          vmthis.imgPreview = URL.createObjectURL(vmthis.file)
        } else {
          vmthis.modalFailedUpload = true
        }
        _URL.revokeObjectURL(objectUrl)
      }
      img.src = objectUrl
    },
    cancelImg() {
      this.imgPreview = ''
      this.file = null
    },
    onChangeAction() {
      this.selectedReward = {}
      this.link = ''
      this.cancelImg()
    },
    liffLogin() {
      console.log(window.location.href)
      let qsObject = {
        return_url: encodeURI(window.location.href),
      }
      const qs = new URLSearchParams(qsObject)
      let uri = `https://liff.line.me/${this.liffId}?${qs}`

      // let uri = `${Vue.bzbsConfig.bzbsConsumerUrl}/landing2/${
      //   cache.get('CRMUser').AppId
      // }?${qs}`
      // let uri = `${Vue.bzbsConfig.bzbsConsumerUrl}/landing?${qs}`
      // let uri = `https://localhost:8081/landing?${qs}`
      const data = {
        valueAction: this.valueAction,
        selectedReward: this.selectedReward,
        link: this.link,
        valueArea: this.valueArea,
        value: this.value,
        dateTime: this.dateTime,
        imgPreview: this.imgPreview,
      }
      sessionStorage.setItem('smartTargetData', JSON.stringify(data))
      console.log('smartTargetData', JSON.stringify(data))
      console.log(uri)
      window.location.replace(uri)
      console.log(1)
    },
    logoutLiff() {
      liff.logout()
      this.logginLine = false
    },
    sendTestToLine(lineId) {
      console.log(lineId)
      this.handleLoading(true)
      if (this.lineIdStore) {
        let schedule = 'now'
        if (this.value === 2) schedule = 'schedule'
        const data = {
          dateTime: this.dateTime,
          link: this.link,
          schedule,
          imgUrl: this.imgPreview,
          text: this.valueArea,
        }
        BzbsSmarttarget.sendTestToLine(this.lineIdStore, data)
          .then(res => {
            console.log(
              'send test to line',
              res.data.RequestToLineChannelLog_ResponseStatusCode,
            )
            this.handleLoading(false)
            if (res.data.RequestToLineChannelLog_ResponseStatusCode == 400) {
              this.modalFailCreate(true)
            } else {
              this.alertModalAction(
                this.lbl['alert-box-success-header'],
                this.lbl['smart-successsentlinetest'],
                'success',
                true,
              )
            }
          })
          .catch(() => {
            this.handleLoading(false)
            this.modalFailCreate(true)
          })
          .finally(() => {
            this.handleLoading(false)
          })
      } else {
        this.liffLogin()
      }
    },
    getRewardList() {
      this.handleLoading(true)
      BzbsPlanReward.getCRMPlusBurnCampaings()
        .then(res => {
          this.rewardDataList = res.data.filter(i => i.Status === 3)
          if (
            this.$route.query.id &&
            this.$route.query.id !== 'undefined' &&
            this.$route.query.mode !== 'create'
          ) {
            this.getDetailById(this.$route.query.id)
          }
          this.handleLoading(false)
        })
        .catch(error => {
          console.log(error)
          this.handleLoading(false)
        })
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      BzbsProfile.getCRMPlusProfile()
        .then(res => {
          this.handleLoading(false)
          this.lineChanelId = res.data.LineMessageChannelId
          this.liffId = res.data.LiffId
          this.LiffLanding = res.data.LiffLanding
          this.isUnlimitSMS = !res.data.LimitSms
          this.limitSmsCurrent = res.data.LimitSmsCurrent
          this.limitLineCurrent = res.data.LineChannelTargetLimitValue
        })
        .catch(error => {
          this.handleLoading(false)
          console.log('apiGetAppCategoryList error', error)
        })
    },
    getRewardId(link) {
      var rewardId
      try {
        rewardId = link.split('%2F')[1].split('%3F')[0]
      } catch {
        if (link.includes('detail/')) {
          rewardId = link.split('detail/').pop()
        } else {
          rewardId = ''
        }
      }
      return rewardId
    },
    getDetailById(id) {
      BzbsSmarttarget.getCampiagnDetail(id)
        .then(res => {
          console.log('res', res)
          this.messageType = res.data.data.SendType
          if (this.toLower(this.messageType) == 'line') {
            var jsontextData = null
            var jsonimageData = null
            try {
              jsontextData = JSON.parse(
                res.data.data.LineTemplate.LineSSTemplateActions[0].Json,
              )
            } catch {
              jsontextData = null
            }
            try {
              jsonimageData = JSON.parse(
                res.data.data.LineTemplate.LineSSTemplateActions[1].Json,
              )
            } catch {
              jsonimageData = null
            }

            if (jsonimageData && jsonimageData.baseUrl) {
              if (this.$route.query.mode == 'edit') {
                this.imgPreview = jsonimageData.baseUrl
              } else {
                this.imgPreview = jsonimageData.baseUrl + '/460'
              }
            }
            if (jsontextData && jsontextData.text) {
              this.valueArea = jsontextData.text.split(' : ')[0]
              this.link = jsontextData.text.split(' : ')[1]
              if (this.link) this.valueAction = 'Exiting Link'
            }

            if ((jsonimageData && jsonimageData.actions) || this.link) {
              this.valueAction = 'Rewards'
              if (jsonimageData && jsonimageData.actions) {
                if (jsonimageData.actions[0]) {
                  if (
                    jsonimageData.actions[0].linkUri.includes(
                      'campaigndetail',
                    ) ||
                    jsonimageData.actions[0].linkUri.includes('detail')
                  ) {
                    this.rewardId = this.getRewardId(
                      jsonimageData.actions[0].linkUri,
                    )
                  } else {
                    this.valueAction = 'Exiting Link'
                    this.link = jsonimageData
                      ? jsonimageData.actions[0].linkUri
                      : ''
                  }
                } else {
                  if (this.link) {
                    this.valueAction = 'Exiting Link'
                  } else {
                    this.valueAction = 'No Action'
                  }
                }
              } else if (
                this.link.includes('campaigndetail') ||
                this.link.includes('detail')
              ) {
                this.rewardId = this.getRewardId(this.link)
              } else {
                this.valueAction = 'Exiting Link'
              }

              if (this.rewardId) {
                let filterReward = this.rewardDataList.filter(i => {
                  return i.ID === parseInt(this.rewardId)
                })
                this.selectedReward = filterReward[0]
                this.createRewardLink()
              }
            }
          } else if (this.toLower(this.messageType) == 'email') {
            this.content = this.getContent(
              res.data.data.EmailTemplate.Html,
              res.data.data.ActionType,
            )
            this.subject = res.data.data.EmailTemplate.Subject
            this.getRewardLink(
              res.data.data.EmailTemplate.Html,
              res.data.data.ActionType,
            )
            this.link = this.getLink(
              res.data.data.EmailTemplate.Html,
              res.data.data.ActionType,
            )
          } else if (this.toLower(this.messageType) == 'sms') {
            this.valueArea = res.data.data.SmsTemplate.Message
            this.link = this.getLink(
              res.data.data.SmsTemplate.FullUrl,
              res.data.data.ActionType,
            )
            if (this.link)
              this.valueArea =
                res.data.data.SmsTemplate.Message.split('<p><a href="')[0]
          }

          this.valueAction = this.mapDisplayAction(res.data.data.ActionType)

          this.value = res.data.data.Schedule == 'now' ? 1 : 2
          this.dateTime.startDate = moment(
            new Date(res.data.data.ScheduleDate * 1000),
          )
          this.dateTime.startTime = moment(
            new Date(res.data.data.ScheduleDate * 1000),
          ).add(-7, 'hours')
          this.resUserList = {
            ID: res.data.data.UserList[0].UserListId,
            Name: res.data.data.UserList[0].Name,
            Description: res.data.data.UserList[0].Description,
            UserCount: res.data.data.UserList[0].UserCount,
          }
          this.adsId = res.data.data.Id
          this.userListId = res.data.data.UserList[0].UserListId
          this.campaignStatus =
            res.data.data.Status[0].toUpperCase() +
            res.data.data.Status.substring(1)
          let sendDateParse = Date.parse(this.dateTime.startTime)
          if (sendDateParse > Date.parse(new Date()))
            this.campaignStatus = 'Created'

          this.campaignResult = res.data.data.MarketingCampaignResult
          if (this.$route.query.mode !== 'create' && this.$route.query.target) {
            this.campaignResult.TotalSent = this.$route.query.target
          }
          BzbsSmarttarget.getDetailSent(this.adsId)
            .then(res => {
              console.log('getDetailSent', res)
              this.detailDataSent = res.data.data.list.map(item => {
                return {
                  UserID: item.UserID,
                  Email: item.Email,
                  ContactNumber: item.TelNo,
                  FirstName: item.FirstName,
                  LastName: item.LastName,
                  Timestamp: moment(new Date(item.Timestamp * 1000)).format(
                    'DD/MM/YYYY HH:MM',
                  ),
                }
              })
            })
            .catch(error => {
              console.log('apiGetAppCategoryList error', error)
            })
          BzbsSmarttarget.getDetailClick(this.adsId)
            .then(res => {
              console.log('getDetailClick', res.data.data.data)
              this.detailDataClick = res.data.data.data.map(item => {
                return {
                  UserID: item.user_id,
                  Email: item.email,
                  LineId: item.line_id,
                  ContactNumber: item.mobile,
                  FirstName: item.name,
                  Timestamp: item.click_date,
                }
              })
            })
            .catch(error => {
              console.log('apiGetAppCategoryList error', error)
            })
          BzbsSmarttarget.getDetailTotal(this.userListId)
            .then(res => {
              console.log('getDetailTotal', res)
              this.detailDataTotal = res.data.data.list.map(item => {
                return {
                  UserID: item.UserID,
                  Email: item.Email,
                  ContactNumber: item.ContactNumber,
                  FirstName: item.FirstName,
                  LastName: item.LastName,
                  Timestamp: moment(new Date(item.Timestamp * 1000)).format(
                    'DD/MM/YYYY HH:MM',
                  ),
                }
              })
            })
            .catch(error => {
              console.log('apiGetAppCategoryList error', error)
            })
          BzbsSmarttarget.getSmarttargetListRedeem(this.adsId)
            .then(res => {
              console.log('getSmarttargetListRedeem', res)
              this.detailDataRedeem = res.data.data.map(item => {
                return {
                  UserID: item.user_id,
                  Email: item.email,
                  ContactNumber: item.mobile,
                  FirstName: null,
                  LastName: null,
                  Name: item.name,
                  Timestamp: item.redeem_date,
                }
              })
            })
            .catch(error => {
              console.log('apiGetAppCategoryList error', error)
            })
          BzbsSmarttarget.getSmarttargetListUsed(this.adsId)
            .then(res => {
              console.log('getSmarttargetListUsed', res)
              this.detailDataUsed = res.data.data.map(item => {
                return {
                  UserID: item.user_id,
                  Email: item.email,
                  ContactNumber: item.mobile,
                  FirstName: null,
                  LastName: null,
                  Name: item.name,
                  Timestamp: item.redeem_date,
                }
              })
            })
            .catch(error => {
              console.log('apiGetAppCategoryList error', error)
            })
        })
        .catch(error => {
          console.log('apiGetAppCategoryList error', error)
        })
    },
    toLower(text) {
      if (text) {
        return text.toLowerCase()
      } else {
        return
      }
    },
    mapAction(value) {
      switch (value) {
        case 'Rewards':
          return 'Reward'
        case 'Exiting Link':
          return 'Exitinglink'
        case 'No Action':
          return 'NoAction'

        default:
          return 'NoAction'
      }
    },
    mapDisplayAction(value) {
      switch (value) {
        case 'Reward':
          return 'Rewards'
        case 'Exitinglink':
          return 'Exiting Link'
        case 'NoAction':
          return 'No Action'

        default:
          return 'No Action'
      }
    },
    getContent(template, type) {
      if (type == 'Exitinglink') {
        return template.split('<p><a href="')[0]
      } else if (type == 'Reward') {
        try {
          this.link = template.split('href="')[1].split('">')[0]
        } catch {
          this.link = null
        }
        if (template.includes('id="reward_button"')) {
          return template.split(
            '<div style="<div id="reward_button" style="background-color:#ff9800; width:fit-content; padding:16px; border-radius: 4px">',
          )[0]
        } else {
          return template.split(
            '<div style="background-color:#ff9800; width:fit-content; padding:16px; border-radius: 4px">',
          )[0]
        }
      } else {
        return template
      }
    },
    getLink(fullUrl, type) {
      if (type !== 'NoAction') {
        if (fullUrl && fullUrl.includes('href')) {
          try {
            return fullUrl.split('href="')[1].split('"')[0]
          } catch {
            return fullUrl
          }
        } else {
          return fullUrl
        }
      } else {
        return ''
      }
    },
    getRewardLink(template, type) {
      if (type == 'Reward') {
        try {
          this.link = template.split('href="')[1].split('">')[0]
        } catch {
          this.link = null
        }
        if (template || template.includes('id="reward_button"')) {
          try {
            this.rewardId = this.getRewardId(
              template.split('font-weight: bold;" href="')[1].split('"')[0],
            )
          } catch {
            this.rewardId = null
          }
        } else {
          this.rewardId = null
        }
      } else {
        this.rewardId = null
      }
      if (this.rewardId) {
        let filterReward = this.rewardDataList.filter(i => {
          return i.ID === parseInt(this.rewardId)
        })
        this.selectedReward = filterReward[0]
        this.createRewardLink()
      }
    },
    createCampaign(state, adsId) {
      let schedule = 'now'
      if (this.value === 2) schedule = 'schedule'
      console.log('Vue.bzbsConfig', Vue.bzbsConfig)
      var data = {}

      if (this.toLower(this.messageType) == 'email') {
        data = {
          dateTime: this.dateTime,
          link: this.link,
          schedule,
          imgUrl: this.imgPreview,
          emailTemplate: {
            Subject: this.subject,
            Html: this.customHTML(this.content),
          },
          actionType: this.mapAction(this.valueAction),
          messageType: this.messageType,
        }
      } else if (this.messageType.toUpperCase() == 'SMS') {
        data = {
          dateTime: this.dateTime,
          link: this.link,
          schedule,
          imgUrl: this.imgPreview,
          smsTemplate: {
            Message: this.customHTML(this.valueArea),
            FullUrl: this.link,
          },
          actionType: this.mapAction(this.valueAction),
          messageType: this.messageType,
        }
      } else {
        data = {
          dateTime: this.dateTime,
          link: this.link,
          schedule,
          imgUrl: this.imgPreview,
          text: this.valueArea,
          actionType: this.mapAction(this.valueAction),
          messageType: this.messageType,
        }
      }
      BzbsSmarttarget.createCampaign(data, this.resUserList, state, adsId)
        .then(res => {
          this.handleLoading(false)
          this.resCampaign = res.data.data
          sessionStorage.removeItem('target')
          sessionStorage.removeItem('smartTargetData')
          if (adsId) {
            this.alertModalAction(
              this.lbl['alert-box-success-header'],
              this.lbl['smarttarget-successupdate'],
              'success',
              true,
            )
          } else {
            this.alertModalAction(
              this.lbl['alert-box-success-header'],
              this.lbl['smarttarget-successcreate'],
              'success',
              true,
            )
          }

          if (state == 'draft') {
            this.$router.push({
              name: 'SmartNotify',
            })
          } else {
            this.$router.push({
              name: 'SmartNotify',
              query: {
                create: 'success',
                total: this.totalUser ? this.totalUser : 0,
              },
            })
          }
        })
        .catch(err => {
          this.handleLoading(false)
          this.modalFailCreate(true)
          console.log(err)
        })
    },
    createUserList(name, description, filename) {
      this.isLoadTotal = true
      BzbsSmarttarget.createUserList(name, description, filename)
        .then(res => {
          this.resUserList = res.data.data
          if (this.resUserList.ID) {
            BzbsSmarttarget.getDetailTotal(this.resUserList.ID)
              .then(res => {
                console.log('getDetailTotal', res)
                this.isLoadTotal = false
                this.totalUser = parseInt(res.data.data.total)
                this.detailDataTotal = res.data.data.list.map(item => {
                  return {
                    UserID: item.UserID,
                    Email: item.Email,
                    ContactNumber: item.ContactNumber,
                    FirstName: item.FirstName,
                    LastName: item.LastName,
                    Timestamp: moment(new Date(item.Timestamp * 1000)).format(
                      'DD/MM/YYYY HH:MM',
                    ),
                  }
                })
              })
              .catch(error => {
                console.log('apiGetAppCategoryList error', error)
              })
          }
        })
        .catch(err => console.log(err))
    },
    // GoBack
    btnCancelGoBack() {
      this.modalGoBack = false
    },
    cancelGoto() {
      sessionStorage.removeItem('target')
      sessionStorage.removeItem('smartTargetData')
      this.goto('SmartNotify')
    },
    clickSaveDraft() {
      if (this.checkLinkValid()) {
        if (this.toLower(this.messageType) == 'email') {
          this.validateContentSize(this.customHTML(this.content)).then(
            result => {
              if (result) {
                this.upLoadImg('draft')
              } else {
                this.handleLoading(false)

                this.alertModalAction(
                  this.lbl['alert-box-error-header'],
                  this.lbl['smart-target-warnign-over-size'],
                  'error',
                  true,
                )
              }
            },
          )
        } else {
          this.upLoadImg('draft')
        }
      } else {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          this.lbl['smart-target-wrong-link'],
          'error',
          true,
        )
      }
    },
    upLoadImg(state, adsId = false) {
      console.log('state :: ', state)
      this.handleLoading(true)
      if (state == 'draft') {
        Account.bzbsAnalyticTracking(
          'smart_target_create',
          'smart_target',
          'click_smart_target_create_save_draft',
          'on click',
        )
      }
      if (this.file) {
        BzbsSmarttarget.upLoadImg(this.file)
          .then(res => {
            this.imgPreview = res.data.data.imageMapMessage.baseUrl + '/1040'
            this.createCampaign(state, adsId)
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.createCampaign(state, adsId)
      }
    },
    sentTest() {
      this.showInviteBox = false
      this.handleLoading(true)

      if (this.messageType.toUpperCase() == 'SMS') {
        Account.bzbsAnalyticTracking(
          'smart_target_create',
          'smart_target',
          'click_smart_target_create_test_sms',
          'on click',
        )
      } else {
        Account.bzbsAnalyticTracking(
          'smart_target_create',
          'smart_target',
          'click_smart_target_create_test_email',
          'on click',
        )
      }
      if (this.file) {
        BzbsSmarttarget.upLoadImg(this.file)
          .then(res => {
            this.imgPreview = res.data.data.imageMapMessage.baseUrl + '/1040'
            this.sentTestMessage()
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.sentTestMessage()
      }
    },
    sentTestMessage() {
      return new Promise(resolve => {
        let schedule = 'now'
        let text =
          this.toLower(this.messageType) == 'email'
            ? this.content
            : this.valueArea
        if (this.value === 2) schedule = 'schedule'
        var data = {
          sender: this.inviteUser.replaceAll(' ', ''),
          dateTime: this.dateTime,
          link: this.link,
          schedule,
          imgUrl: this.imgPreview,
          text: text,
        }
        if (this.messageType.toUpperCase() == 'SMS') {
          data = {
            sender: this.inviteUser.replaceAll(' ', ''),
            Message: this.link ? text + ' ' + this.link : text,
            FullUrl: this.link,
          }
        }
        if (this.toLower(this.messageType) == 'email') {
          data = {
            sender: this.inviteUser.replaceAll(' ', ''),
            subject: this.subject,
            Message: this.customHTML(text),
          }
        }
        BzbsSmarttarget.sendTestSmartTarget(this.messageType, data)
          .then(res => {
            console.log('send test', res.data)
            this.handleLoading(false)
            if (res.data.success) {
              this.alertModalAction(
                this.lbl['alert-box-success-header'],
                this.lbl['smart-successsentlinetest'],
                'success',
                true,
              )
            } else {
              this.modalFailCreate(true)
            }
            resolve(res.data)
          })
          .catch(() => {
            this.handleLoading(false)
            this.modalFailCreate(true)
            this.inviteUser = null
          })
      })
    },
    customHTML(mainText) {
      var link = null
      var reward = null
      var result = mainText

      if (this.valueAction === 'Exiting Link') {
        if (this.toLower(this.messageType) == 'sms') {
          link = ` ${this.link}`
        } else {
          link = `<p><a href="${this.link}">${this.link}</a></p>`
        }
        result = mainText + link
      }
      if (this.valueAction === 'Rewards') {
        reward = `<div id="reward_button" style="background-color:#ff9800; width:fit-content; padding:16px; border-radius: 4px"><a style="color:#ffffff; text-decoration:unset; font-weight: bold;" href="${this.link}">View Reward</a></div>`
        result = mainText + reward
      }
      console.log('result ::', result)
      return result
    },
    upLoadImgToTestLine() {
      Account.bzbsAnalyticTracking(
        'smart_target_create',
        'smart_target',
        'click_smart_target_create_test_line',
        'on click',
      )
      if (this.toLower(this.messageType) !== 'line') {
        if (this.toLower(this.messageType) == 'email') {
          this.validateContentSize(this.customHTML(this.content)).then(
            result => {
              if (result) {
                this.showInviteBox = true
                this.inviteUser = null
              } else {
                this.handleLoading(false)
                this.alertModalAction(
                  this.lbl['alert-box-error-header'],
                  this.lbl['smart-target-warnign-over-size'],
                  'error',
                  true,
                )
              }
            },
          )
        } else {
          this.showInviteBox = true
          this.inviteUser = null
        }
      } else {
        this.showInviteBox = false
        if (this.checkLinkValid()) {
          if (this.file) {
            BzbsSmarttarget.upLoadImg(this.file)
              .then(res => {
                this.imgPreview =
                  res.data.data.imageMapMessage.baseUrl + '/1040'
                this.sendTestToLine()
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            this.sendTestToLine()
          }
        } else {
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            this.lbl['smart-target-wrong-link'],
            'error',
            true,
          )
        }
      }
    },
    toggleDeleteModal(type) {
      this.isDeleteModal = type
      console.log(123)
    },
    deleteCampiagn() {
      this.handleLoading(true)
      BzbsSmarttarget.deleteCampiagn(this.$route.query.id)
        .then(res => {
          console.log(res)
          this.toggleDeleteModal(false)
          this.alertModalAction(
            this.lbl['alert-box-success-header'],
            this.lbl['smarttarget-successcancled'],
            'success',
            true,
          )
          this.$router.push({ name: 'SmartNotify' })
        })
        .catch(err => console.log(err))
        .finally(() => this.handleLoading(false))
    },
    exportExcelPoint() {
      this.handleLoading(true)

      const data = this.dataExportPoint.map(item => {
        var date = ''
        if (item.Date != null && item.Date != '-' && item.Date != '') {
          date = this.datetimeShortFormatUTC(item.Date)
        } else {
          date = '-'
        }
        var CustomerId = this.lbl['dashboard-transactions-user-id']
        var TransactionId = this.lbl['dashboard-transactions-list']
        var CreateDate = this.lbl['dashboard-transactions-date-time']
        var BranchName = this.lbl['dashboard-transactions-branch-name']
        var Amount = this.lbl['dashboard-transactions-quantity']
        var Points = this.lbl['dashboard-transactions-earn-points']
        var Description = this.lbl['dashboard-transactions-reference-code']
        var Source = this.lbl['dashboard-transactions-reference-source']

        return {
          [CustomerId]: item.UserID ? item.UserID : '-',
          [TransactionId]: item.TransactionID ? item.TransactionID : '-',
          [CreateDate]: date ? date : '-',
          [BranchName]: item.Branch ? item.Branch : '-',
          [Amount]: { t: 'n', v: item.Amount, z: '#,##0.00' },
          [Points]: { t: 'n', v: item.GivePoints, z: '0' },
          [Description]: item.Description ? item.Description : '-',
          [Source]: item.Source ? item.Source : '-',
        }
      })

      if (data.length <= 10000) {
        const dataWS = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(
          wb,
          'CRMPlus_' + moment(new Date()).format('YYYY-MM-DD') + '.xlsx',
        )
      } else {
        for (var i = 0; i < Math.ceil(data.length / 10000); i++) {
          var data_WS = XLSX.utils.json_to_sheet(
            data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
            { dense: true },
          )

          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data_WS)
          XLSX.writeFile(
            wb,
            'CRMPlus_' +
              (i + 1) +
              '_' +
              moment(new Date()).format('YYYY-MM-DD') +
              '.xlsx',
          )
        }
      }
      this.handleLoading(false)
    },
    checkLinkValid() {
      if (this.link && this.link.includes('/landing')) {
        return false
      } else {
        return true
      }
    },
    displayPageTitle(type, title) {
      try {
        return title.replace('{0}', this.mapType(type))
      } catch {
        return this.lbl['smart-target-header-catch']
      }
    },
    mapType(type) {
      if (type) {
        var lower = type.toLowerCase()
        switch (lower) {
          case 'sms':
            return 'SMS'
          case 'line':
            return 'Line'
          case 'email':
            return 'Email'
        }
      } else {
        return ''
      }
    },
    validateContentSize(content) {
      return new Promise(resolve => {
        const max = 36000 //max 36,000 byte
        if (content) {
          const byteSize = str => new Blob([str]).size
          const result = byteSize(content)
          if (result > max) {
            resolve(false)
          } else {
            resolve(true)
          }
        } else {
          resolve(true)
        }
      })
    },
    handleClickUserDetail() {
      this.$route.query.mode === 'create'
        ? this.modaluserdetail()
        : this.modaldetail(true, 'Target')
    },
    handleErr(arr) {
      var result = {}
      if (arr.length) {
        result.prefix = arr.includes('prefix')
        result.internal = arr.includes('internal')
      }

      return result
    },
  },
}
</script>

<style lang="scss">
@import '@/style/inactiveuser.scss';
.invite-container {
  display: grid;
  grid-template-columns: 1fr 70px;
  grid-column-gap: 4px;

  .invite-btn {
    border-radius: 4px;
    text-align: center;
    background: #ff9800;
    border: solid 1px #ff9800;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    &:disabled {
      background: #f1f1f1;
      border: solid 1px #f1f1f1;
    }
  }
}
.warning-input {
  font-size: 14px;
  color: $text-dark-gray;
}
.ant-time-picker-input {
  height: 40px;
}
.ant-modal {
  max-width: 90vw;
}

.ant-modal-root .ant-modal-body {
  background: #fafafa;
  padding: 16px;
}
.ant-spin-dot-item {
  background-color: #faad14;
}
</style>

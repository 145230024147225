<template>
  <div>
    <div class="row box-publish">
      <div class="col-md-6 col-12 d-flex justify-content-center">
        <div class="previewContainer">
          <img
            src="@/assets/images/mobile_email_page.svg"
            class="img-mobile"
            alt="img"
          />
          <div class="message-container">
            <div v-if="subject" class="email-subject">
              <span class="subject">
                {{ subject }}
              </span>
              <span class="inbox-container">inbox</span>
            </div>
            <div v-if="imgPreview" class="box-img-email">
              <img
                v-if="!imgPreview"
                src="@/assets/images/blank-photo.png"
                class=""
                alt="img"
                width="100%"
              />
              <img v-else :src="imgPreview" class="w-100 h-100" alt="img" />
            </div>
            <div v-if="text || link" class="email-message">
              <div>
                <div class="text" v-html="text"></div>
                <span>{{ ' ' }}</span>
                <span v-if="valueAction !== 'Rewards'" class="link">
                  {{ link && !imgPreview ? link : null }}
                </span>
              </div>
            </div>
            <div v-if="valueAction === 'Rewards'" class="view-reward-btn">
              View Reward
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 text-left">
        <div>
          <span class="title-preview">{{ lbl['send_to'] }}</span
          ><span class="detail-preview">{{
            targetBoxData.heading[currentLocale]
          }}</span>
        </div>
        <br />
        <div class="box-text d-flex">
          <div class="title-preview">{{ 'Target:' }}</div>
          <div class="d-flex" style="padding: 30px">
            <div class="main-account">
              {{ usercount }}
              <span class="sub-account">
                {{ lbl['smart-target-account'] }}
              </span>
            </div>
          </div>
        </div>
        <br />
        <!-- Waiting phase3-->
        <div v-if="false" class="box-text d-flex">
          <div class="title-preview">{{ 'Your Balance:' }}</div>
          <div class="balance-conatainer">
            <img
              src="@/assets/images/account_box.svg"
              class="logo"
              alt="account logo"
            />
            <div class="balance-account">
              {{ 'My Wallet' }}
              <span class="balance">
                {{ balance ? balance : 0 }}
              </span>
            </div>
          </div>
        </div>
        <!-- <br /> -->
        <span class="title-preview">{{ lbl['with'] }}:</span
        ><span class="detail-preview">{{ valueAction }}</span>
        <br />
        <div class="pl-5" v-if="valueAction === 'Exiting Link' && link">
          {{ link }}
        </div>
        <div
          class="box-item"
          v-if="selectedReward.ID && valueAction === 'Rewards'"
        >
          <img
            :src="selectedReward.ImageUrl"
            alt=""
            style="width: 50px; height: 50px; object-fit: cover"
          />
          <div class="box-detail-item">
            <span class="detail-item">{{
              currentLocale == 'th'
                ? selectedReward.Name
                : selectedReward.NameEN
            }}</span>
          </div>
        </div>
        <div
          class="box-item-total"
          v-if="selectedReward.ID && valueAction === 'Rewards'"
        >
          <div class="box-detail-total">
            <span class="detail-item"
              >{{ lbl['total-quota'] }} {{ selectedReward.Quantity }}</span
            >
            <br />
            <span class="detail-item"
              >{{ lbl['period'] }}
              {{ formatDateString(selectedReward.StartDate * 1000) }} -
              {{ formatDateString(selectedReward.EndDate * 1000) }}</span
            >
          </div>
        </div>
        <br />
        <div v-if="dateTime.startDate" class="warringdate">
          <div class="title">
            <img src="@/assets/images/icon-error.png" alt="" />
            <span>{{ lbl['smart-target-previewwarring'] }}</span>
          </div>
          <div class="details">
            {{ lbl['smart-target-previewwarringtext'] }}
          </div>
        </div>
        <span class="title-preview">{{ lbl['time'] }}:</span>
        <span v-if="dateTime.startDate" class="detail-preview">
          {{ formatDateString(dateTime.startDate) }}
          : {{ formatTimeString(dateTime.startTime) }}
        </span>
        <span v-if="dateTime.endDate" class="detail-preview">
          {{ formatDateString(dateTime.endDate) }}
          : {{ formatTimeString(dateTime.endTime) }}
        </span>
        <span v-if="!dateTime.startDate" class="detail-preview">
          {{ formatDateString(new Date()) }} :
          {{ formatTimeString(new Date()) }}
        </span>
      </div>
    </div>
    <!-- Footer -->
    <div class="row footer-btn">
      <div class="col-12 mt-4 mb-2">
        <a class="btn-back mr-3" @click="closeModal()">
          {{ lbl['btn_back'] }}
        </a>
        <a class="btn-use-image ml-3" @click="confirmCreate">
          {{ lbl['pub_campaign'] }}
        </a>
      </div>
    </div>
    <!-- Footer -->
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Account from '@/helper/AccountHelper.js'
import moment from 'moment'
import Locale from '@/helper/locale.js'
export default {
  name: 'PreviewAndPublishEmail',
  mixins: [Mixin],
  props: {
    imgPreview: {
      type: String,
    },
    selectedReward: {
      type: Object,
    },
    link: {
      type: String,
    },
    valueAction: {
      type: String,
    },
    subject: {
      type: String,
    },
    text: {
      type: String,
    },
    dateTime: {
      type: Object,
    },
    targetBoxData: {
      type: Object,
    },
    createCampaign: {
      type: Function,
    },
    modalConfirmCreate: {
      type: Function,
    },
    usercount: {
      type: Number,
    },
    balance: {
      type: [Number, String],
    },
  },
  data: function () {
    return {
      currentLocale: 'th',
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'select-artwork',
      onFieldsChange: prop => {
        if (prop.isFieldsTouched()) {
          this.formTouch = true
        }
      },
    })
  },
  created() {
    this.setLocale()

    this.storetype = Account.getStoreType()
    console.log('storetype : ', this.storetype)
  },
  mounted() {
    this.currentLocale = Locale.getLocaleShort()
  },
  methods: {
    formatDateString(dateString, format = 'DD/MM/YYYY', calHours = null) {
      return moment(dateString).add(calHours, 'hours').format(format)
    },
    formatTimeString(dateString, format = 'HH:mm', calHours = null) {
      return moment(dateString).add(calHours, 'hours').format(format)
    },
    confirmCreate() {
      Account.bzbsAnalyticTracking(
        'smart_target_create',
        'smart_target',
        'click_smart_target_create_publish',
        'on click',
      )
      if (this.dateTime.startDate) {
        this.createCampaign('publish')
      } else {
        this.closeModal()
        this.modalConfirmCreate(true)
      }
    },
    setLocale() {
      //   _.forEach(this.columnsDetail, column => {
      //     column.title = this.lbl[column.key]
      //   })
    },
    closeModal() {
      Account.bzbsAnalyticTracking(
        'smart_target_create',
        'smart_target',
        'click_smart_target_create_publish_back',
        'on click',
      )
      this.$emit('closeModal', true)
    },
  },
}
</script>
<style lang="scss" scoped>
.warringdate {
  padding: 9px 16px;
  border-radius: 2px;
  border: solid 1px #ffccc7;
  background-color: #fff1f0;
  margin: 20px 0 30px 0;
  color: black;
  .title {
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .details {
    margin-left: 37px;
  }
}
.btnLink {
  width: 100%;
  height: 38px;
  background-color: #17c950;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  margin-top: 4px;
}
.previewContainer {
  display: flex;
  position: relative;
  .menubutton {
    position: absolute;
    bottom: 88px;
    right: 13px;
    width: 235px;
    height: 37px;
    background-color: #35435e;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
  }
}
.main-account {
  font-size: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  text-align: left;
  color: #424242;

  .sub-account {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.15px;
    text-align: left;
    color: #686868;
  }
}
.message-container {
  background-color: #ffffff;
  width: 260px;
  height: 436px;
  top: 118px;
  left: 21px;
  border-radius: 0;
  overflow-y: scroll;
  position: absolute;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.email-subject {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 31.5px;
  letter-spacing: 0.15px;
  text-align: left;

  .subject {
    text-align: left;
    overflow-wrap: anywhere;
  }

  .inbox-container {
    font-size: 12px;
    background-color: #bdbdbd;
    padding: 2px 4px;
    border-radius: 4px;
    font-weight: 500;
  }
}
.email-message {
  margin: 16px 0;
  color: #424242;
  text-align: left;

  .link {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #1e88e5;
  }
}
.view-reward-btn {
  cursor: pointer;
  width: 140px;
  border-radius: 4px;
  background: #ff9800;
  padding: 8px 16px;
  color: #ffffff;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.box-img-email {
  width: 240px;
  height: 240px;
  margin: 5px;
  border-radius: 5px;
  overflow: hidden;
}

.balance-conatainer {
  margin-left: 30px;
  border-radius: 4px;
  background: #fff3e0;
  padding: 9px 16px;
  display: flex;
  justify-content: space-between;
  color: #f57c00;

  .logo {
    width: 40px;
    height: 40px;
  }
  .balance-account {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 12px;

    .balance {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>
<style>
.ql-align-right {
  text-align: right !important;
}
.ql-align-center {
  text-align: center !important;
}
.ql-align-left {
  text-align: left !important;
}
</style>
